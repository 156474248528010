// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Api from "../../../../api/Api.res.js";
import * as Url from "../../../../utils/Url.res.js";
import * as Char from "../../../../libs/Char.res.js";
import * as Hooks from "../../../../libs/Hooks.res.js";
import * as Label from "../../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Select from "../../../../styleguide/forms/Select/Select.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Debounce from "rescript-debounce/src/Debounce.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Textarea from "../../../../styleguide/forms/Textarea/Textarea.res.js";
import * as Uploader from "../../../../styleguide/forms/Uploader/Uploader.res.js";
import * as ReactForm from "../../../../libs/ReactForm.res.js";
import * as TextField from "../../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as DatePicker from "../../../../styleguide/forms/DatePicker/DatePicker.res.js";
import * as IconUpload from "../../../../styleguide/icons/IconUpload.res.js";
import * as Visibility from "../../../../libs/Visibility.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PervasivesU from "rescript/lib/es6/pervasivesU.js";
import * as $$Notification from "../../../../bindings/Notification.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as ProjectWizard from "../_components/ProjectWizard/ProjectWizard.res.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as ReactGeoSuggest from "../../../../bindings/ReactGeoSuggest.res.js";
import AddDays from "date-fns/addDays";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardPanel from "../_components/ProjectWizardPanel/ProjectWizardPanel.res.js";
import * as ReactNotifications from "react-notifications";
import * as ReactGeoSuggestField from "../../../../styleguide/forms/ReactGeoSuggestField/ReactGeoSuggestField.res.js";
import * as SignInModalContainer from "../../../../styleguide/modals/SignInModal/SignInModalContainer.res.js";
import * as ProjectWizardNavigation from "../_components/ProjectWizardNavigation/ProjectWizardNavigation.res.js";
import * as CreateNetworkProjectSdWanNetworkConfigurationForm from "./CreateNetworkProjectSdWanNetworkConfigurationForm.res.js";
import * as CreateNetworkProjectSdWanNetworkConfigurationScss from "./CreateNetworkProjectSdWanNetworkConfiguration.scss";
import * as CreateNetworkProjectSdWanNetworkConfiguration_DocumentsWithRfpDetails from "./CreateNetworkProjectSdWanNetworkConfiguration_DocumentsWithRfpDetails.res.js";

var css = CreateNetworkProjectSdWanNetworkConfigurationScss;

function fromString(x) {
  switch (x) {
    case "" :
        return ;
    case "4G/5G Wireless" :
        return "WireLess4G5G";
    case "Broadband" :
        return "Broadband";
    case "Ethernet" :
        return "Ethernet";
    case "Fixed Wireless" :
        return "FixedWireLess";
    case "Internet" :
        return "Internet";
    case "MPLS" :
        return "Mpls";
    case "Satelite" :
        return "Satelite";
    default:
      return PervasivesU.failwith("Unexpected network connection type: " + x);
  }
}

var NetworkConnectionType = {
  fromString: fromString
};

function domId(field, at) {
  return "network-project-sd-wan-network-configuration-" + (field + ("-" + String(at)));
}

function configurationTitle(at) {
  return "#" + (String(at + 1 | 0) + (Char.mdash + "SD-WAN Network Connection"));
}

function add(x, status) {
  return Belt_Array.concat(x, [status]);
}

function remove(x, index) {
  return Belt_Array.keepWithIndex(x, (function (param, idx) {
                return index !== idx;
              }));
}

function toggle(x, index) {
  return Belt_Array.mapWithIndex(x, (function (idx, visibility) {
                if (index === idx) {
                  return Visibility.toggle(visibility);
                } else {
                  return visibility;
                }
              }));
}

function expandAll(x) {
  return Belt_Array.map(x, (function (param) {
                return "Shown";
              }));
}

var Visibilities = {
  add: add,
  remove: remove,
  toggle: toggle,
  expandAll: expandAll
};

function validate(form) {
  Belt_Array.forEachWithIndex(form.input.configurations, (function (ix, param) {
          form.blurConfigurationStartDate(ix);
        }));
}

function results(form) {
  return Belt_Array.mapWithIndex(form.input.configurations, (function (ix, param) {
                return form.configurationStartDateResult(ix);
              }));
}

function isValid(results) {
  return Belt_Array.every(results, (function (result) {
                if (result !== undefined && result.TAG === "Ok") {
                  return true;
                } else {
                  return false;
                }
              }));
}

var ValidateSaveAndContinueLaterForm = {
  validate: validate,
  results: results,
  isValid: isValid
};

var persistDraft = Debounce.make(900, (function (param) {
        if (param[1].TAG === "Draft") {
          return $$Promise.wait(Api.saveNetworkProjectSdWanNetworkConfigurationDraft(param[0]), (function (x) {
                        if (x.TAG === "Ok") {
                          return $$Notification.NotificationManager.success("Draft saved!", "", 1000);
                        }
                        SentryLogger.error1({
                              rootModule: "CreateNetworkProjectSdWanNetworkConfiguration",
                              subModulePath: {
                                hd: "Api",
                                tl: /* [] */0
                              },
                              value: "persistDraft",
                              fullPath: "CreateNetworkProjectSdWanNetworkConfiguration.Api.persistDraft"
                            }, "SaveNetworkProjectSdWanConfigurationDraft::Error", [
                              "Error",
                              x._0
                            ]);
                        $$Notification.NotificationManager.error("Something went wrong trying to save the draft", "", 1000);
                      }));
        }
        
      }));

function submitForm(data, context, onFailure) {
  if (context.TAG === "Draft") {
    return $$Promise.wait(Api.saveNetworkProjectSdWanNetworkConfiguration(data), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_Project.Network.Provider.newSelector("SdWan"));
                  }
                  SentryLogger.error1({
                        rootModule: "CreateNetworkProjectSdWanNetworkConfiguration",
                        subModulePath: {
                          hd: "Api",
                          tl: /* [] */0
                        },
                        value: "submitForm",
                        fullPath: "CreateNetworkProjectSdWanNetworkConfiguration.Api.submitForm"
                      }, "SaveNetworkProjectSdWanNetworkConfiguration::Error", [
                        "Error",
                        x._0
                      ]);
                  onFailure();
                  $$Notification.NotificationManager.error("Something went wrong trying to save the draft", "", 1000);
                }));
  }
  var projectId = context.projectId;
  $$Promise.wait(Api.updateNetworkProjectSdWanNetworkConfiguration(projectId, data), (function (x) {
          if (x.TAG === "Ok") {
            return Url.visit(Routes_Project.Network.Provider.editSelector(projectId, "SdWan"));
          }
          SentryLogger.error1({
                rootModule: "CreateNetworkProjectSdWanNetworkConfiguration",
                subModulePath: {
                  hd: "Api",
                  tl: /* [] */0
                },
                value: "submitForm",
                fullPath: "CreateNetworkProjectSdWanNetworkConfiguration.Api.submitForm"
              }, "UpdateNetworkProjectSdWanNetworkConfiguration::Error", [
                "Error",
                x._0
              ]);
          onFailure();
          $$Notification.NotificationManager.error("Something went wrong trying to update the project", "", 1000);
        }));
}

function saveDraftProjectAndContinueLater(form, context) {
  if (context.TAG !== "Draft") {
    return $$Promise.wait(Api.updateNetworkSdWanDraftProjectAndContinueLater(context.projectId, form.input), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_Project.index);
                  }
                  SentryLogger.error1({
                        rootModule: "CreateNetworkProjectSdWanNetworkConfiguration",
                        subModulePath: {
                          hd: "Api",
                          tl: /* [] */0
                        },
                        value: "saveDraftProjectAndContinueLater",
                        fullPath: "CreateNetworkProjectSdWanNetworkConfiguration.Api.saveDraftProjectAndContinueLater"
                      }, "UpdateNetworkProjectSdWanNetworkConfiguration::Error", [
                        "Error",
                        x._0
                      ]);
                  $$Notification.NotificationManager.error("Something went wrong trying to update the project", "", 1000);
                }));
  }
  validate(form);
  if (isValid(results(form))) {
    return $$Promise.wait(Api.saveNetworkProjectDraftWithDocumentsAndContinueLater(form.input.documents, form.input.geographicalRegion), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_Project.index);
                  }
                  SentryLogger.error1({
                        rootModule: "CreateNetworkProjectSdWanNetworkConfiguration",
                        subModulePath: {
                          hd: "Api",
                          tl: /* [] */0
                        },
                        value: "saveDraftProjectAndContinueLater",
                        fullPath: "CreateNetworkProjectSdWanNetworkConfiguration.Api.saveDraftProjectAndContinueLater"
                      }, "saveDraftProjectAndContinueLater::saveProjectDraft::Error", [
                        "Error",
                        x._0
                      ]);
                  $$Notification.NotificationManager.error("Something went wrong trying to save the draft", "", 1000);
                }));
  }
  
}

function cancelCreation() {
  $$Promise.wait(Api.cancelProjectCreation(), (function (x) {
          if (x.TAG === "Ok") {
            return Url.visit(Routes_Project.index);
          } else {
            return SentryLogger.error1({
                        rootModule: "CreateNetworkProjectSdWanNetworkConfiguration",
                        subModulePath: {
                          hd: "Api",
                          tl: /* [] */0
                        },
                        value: "cancelCreation",
                        fullPath: "CreateNetworkProjectSdWanNetworkConfiguration.Api.cancelCreation"
                      }, "CancelProjectCreation::Error", [
                        "Error",
                        x._0
                      ]);
          }
        }));
}

var Api$1 = {
  persistDraft: persistDraft,
  submitForm: submitForm,
  saveDraftProjectAndContinueLater: saveDraftProjectAndContinueLater,
  cancelCreation: cancelCreation
};

function isAlreadyDocumentsAvailable(alreadyUploadedDocuments) {
  return alreadyUploadedDocuments.length !== 0;
}

function isRegionFieldVisible(documents, alreadyUploadedDocuments, deletedDocumentIds) {
  var remainingUploadedDocuments = Belt_Array.keep(alreadyUploadedDocuments, (function ($$document) {
          if (alreadyUploadedDocuments.length !== 0) {
            return !Js_array.includes($$document.id, deletedDocumentIds);
          } else {
            return false;
          }
        }));
  if (documents.length !== 0) {
    return true;
  } else {
    return remainingUploadedDocuments.length !== 0;
  }
}

function CreateNetworkProjectSdWanNetworkConfiguration(props) {
  var alreadyUploadedDocuments = props.alreadyUploadedDocuments;
  var userLoginStatus = props.userLoginStatus;
  var mobile = props.mobile;
  var options = props.options;
  var context = props.context;
  var initialInput = React.useMemo((function () {
          var geographicalRegion;
          var data;
          if (context.TAG === "Draft") {
            var data$1 = context.data;
            var geographicalRegion$1 = context.geographicalRegion;
            if (data$1 === undefined) {
              return {
                      configurations: [CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.empty()],
                      documents: [],
                      deletedDocumentIds: [],
                      geographicalRegion: geographicalRegion$1,
                      validateRegion: true
                    };
            }
            geographicalRegion = geographicalRegion$1;
            data = data$1;
          } else {
            geographicalRegion = context.geographicalRegion;
            data = context.data;
          }
          return {
                  configurations: data,
                  documents: [],
                  deletedDocumentIds: [],
                  geographicalRegion: geographicalRegion,
                  validateRegion: alreadyUploadedDocuments.length === 0
                };
        }), []);
  var match = React.useState(function () {
        return "Hidden";
      });
  var toggleSignInModal = match[1];
  var signInModal = match[0];
  var form = CreateNetworkProjectSdWanNetworkConfigurationForm.useForm(initialInput, (function (output, form) {
          submitForm(output, context, form.notifyOnFailure);
        }));
  var initialState = React.useMemo((function () {
          var data;
          if (context.TAG === "Draft") {
            var data$1 = context.data;
            if (data$1 !== undefined) {
              data = data$1;
            } else {
              var shown = ["Shown"];
              var hidden = ["Hidden"];
              return {
                      panelsVisibility: shown,
                      additionalRequirementsVisibility: hidden,
                      diverseNetworkConnectionVisibility: hidden,
                      sdWanFeaturesVisibility: hidden,
                      userLoginStatus: userLoginStatus
                    };
            }
          } else {
            data = context.data;
          }
          var shown$1 = Belt_Array.map(data, (function (param) {
                  return "Shown";
                }));
          var hidden$1 = Belt_Array.map(data, (function (param) {
                  return "Hidden";
                }));
          return {
                  panelsVisibility: shown$1,
                  additionalRequirementsVisibility: hidden$1,
                  diverseNetworkConnectionVisibility: hidden$1,
                  sdWanFeaturesVisibility: hidden$1,
                  userLoginStatus: userLoginStatus
                };
        }), []);
  var match$1 = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            if (action === "AddPanel") {
              return {
                      TAG: "UpdateWithSideEffects",
                      _0: {
                        panelsVisibility: Belt_Array.concat(state.panelsVisibility, ["Shown"]),
                        additionalRequirementsVisibility: Belt_Array.concat(state.additionalRequirementsVisibility, ["Hidden"]),
                        diverseNetworkConnectionVisibility: Belt_Array.concat(state.diverseNetworkConnectionVisibility, ["Hidden"]),
                        sdWanFeaturesVisibility: Belt_Array.concat(state.sdWanFeaturesVisibility, ["Hidden"]),
                        userLoginStatus: state.userLoginStatus
                      },
                      _1: (function (param) {
                          form.addConfiguration(CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.empty());
                        })
                    };
            } else {
              return {
                      TAG: "Update",
                      _0: {
                        panelsVisibility: Belt_Array.map(state.panelsVisibility, (function (param) {
                                return "Shown";
                              })),
                        additionalRequirementsVisibility: Belt_Array.map(state.additionalRequirementsVisibility, (function (param) {
                                return "Shown";
                              })),
                        diverseNetworkConnectionVisibility: Belt_Array.map(state.diverseNetworkConnectionVisibility, (function (param) {
                                return "Shown";
                              })),
                        sdWanFeaturesVisibility: Belt_Array.map(state.sdWanFeaturesVisibility, (function (param) {
                                return "Shown";
                              })),
                        userLoginStatus: state.userLoginStatus
                      }
                    };
            }
          }
          switch (action.TAG) {
            case "RemovePanel" :
                var at = action.at;
                return {
                        TAG: "Update",
                        _0: {
                          panelsVisibility: remove(state.panelsVisibility, at),
                          additionalRequirementsVisibility: remove(state.additionalRequirementsVisibility, at),
                          diverseNetworkConnectionVisibility: remove(state.diverseNetworkConnectionVisibility, at),
                          sdWanFeaturesVisibility: remove(state.sdWanFeaturesVisibility, at),
                          userLoginStatus: state.userLoginStatus
                        }
                      };
            case "TogglePanelVisibility" :
                return {
                        TAG: "Update",
                        _0: {
                          panelsVisibility: toggle(state.panelsVisibility, action.at),
                          additionalRequirementsVisibility: state.additionalRequirementsVisibility,
                          diverseNetworkConnectionVisibility: state.diverseNetworkConnectionVisibility,
                          sdWanFeaturesVisibility: state.sdWanFeaturesVisibility,
                          userLoginStatus: state.userLoginStatus
                        }
                      };
            case "ToggleDiverseNetworkConnectionVisibility" :
                return {
                        TAG: "Update",
                        _0: {
                          panelsVisibility: state.panelsVisibility,
                          additionalRequirementsVisibility: state.additionalRequirementsVisibility,
                          diverseNetworkConnectionVisibility: toggle(state.diverseNetworkConnectionVisibility, action.at),
                          sdWanFeaturesVisibility: state.sdWanFeaturesVisibility,
                          userLoginStatus: state.userLoginStatus
                        }
                      };
            case "ToggleSdWanFeaturesVisibility" :
                return {
                        TAG: "Update",
                        _0: {
                          panelsVisibility: state.panelsVisibility,
                          additionalRequirementsVisibility: state.additionalRequirementsVisibility,
                          diverseNetworkConnectionVisibility: state.diverseNetworkConnectionVisibility,
                          sdWanFeaturesVisibility: toggle(state.sdWanFeaturesVisibility, action.at),
                          userLoginStatus: state.userLoginStatus
                        }
                      };
            case "ToggleAdditionalRequirementsVisibility" :
                return {
                        TAG: "Update",
                        _0: {
                          panelsVisibility: state.panelsVisibility,
                          additionalRequirementsVisibility: toggle(state.additionalRequirementsVisibility, action.at),
                          diverseNetworkConnectionVisibility: state.diverseNetworkConnectionVisibility,
                          sdWanFeaturesVisibility: state.sdWanFeaturesVisibility,
                          userLoginStatus: state.userLoginStatus
                        }
                      };
            case "AddDocument" :
                var v = Belt_Array.concat(form.input.documents, [action._0]);
                return {
                        TAG: "SideEffects",
                        _0: (function (param) {
                            form.updateDocuments((function (input, v) {
                                    return {
                                            configurations: input.configurations,
                                            documents: v,
                                            deletedDocumentIds: input.deletedDocumentIds,
                                            geographicalRegion: input.geographicalRegion,
                                            validateRegion: input.validateRegion
                                          };
                                  }), v);
                          })
                      };
            case "RemoveDocument" :
                var $$document = action._0;
                var v$1 = Js_array.filter((function (formDoc) {
                        return formDoc.name !== $$document.name;
                      }), form.input.documents);
                var isRegionEmpty = !isRegionFieldVisible(v$1, alreadyUploadedDocuments, form.input.deletedDocumentIds);
                return {
                        TAG: "SideEffects",
                        _0: (function (param) {
                            form.updateDocuments((function (input, v) {
                                    return {
                                            configurations: input.configurations,
                                            documents: v,
                                            deletedDocumentIds: input.deletedDocumentIds,
                                            geographicalRegion: input.geographicalRegion,
                                            validateRegion: input.validateRegion
                                          };
                                  }), v$1);
                            if (isRegionEmpty) {
                              return form.updateValidateRegion((function (input, param) {
                                            return {
                                                    configurations: input.configurations,
                                                    documents: input.documents,
                                                    deletedDocumentIds: input.deletedDocumentIds,
                                                    geographicalRegion: input.geographicalRegion,
                                                    validateRegion: true
                                                  };
                                          }), true);
                            }
                            
                          })
                      };
            case "RemoveAlreadUploadedDocument" :
                var v$2 = Belt_Array.concat(form.input.deletedDocumentIds, [action._0.id]);
                var isRegionEmpty$1 = !isRegionFieldVisible(form.input.documents, alreadyUploadedDocuments, v$2);
                return {
                        TAG: "SideEffects",
                        _0: (function (param) {
                            form.updateDeletedDocumentIds((function (input, v) {
                                    return {
                                            configurations: input.configurations,
                                            documents: input.documents,
                                            deletedDocumentIds: v,
                                            geographicalRegion: input.geographicalRegion,
                                            validateRegion: input.validateRegion
                                          };
                                  }), v$2);
                            if (isRegionEmpty$1) {
                              return form.updateValidateRegion((function (input, param) {
                                            return {
                                                    configurations: input.configurations,
                                                    documents: input.documents,
                                                    deletedDocumentIds: input.deletedDocumentIds,
                                                    geographicalRegion: input.geographicalRegion,
                                                    validateRegion: true
                                                  };
                                          }), true);
                            }
                            
                          })
                      };
            case "ToggleLoginStatus" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          panelsVisibility: state.panelsVisibility,
                          additionalRequirementsVisibility: state.additionalRequirementsVisibility,
                          diverseNetworkConnectionVisibility: state.diverseNetworkConnectionVisibility,
                          sdWanFeaturesVisibility: state.sdWanFeaturesVisibility,
                          userLoginStatus: action._0
                        },
                        _1: (function (param) {
                            saveDraftProjectAndContinueLater(form, context);
                          })
                      };
            
          }
        }));
  var dispatch = match$1[1];
  var state = match$1[0];
  var tmp;
  if (isRegionFieldVisible(form.input.documents, alreadyUploadedDocuments, form.input.deletedDocumentIds)) {
    var domId$1 = domId("geographical-region", 0);
    var result = form.geographicalRegionResult;
    var tmp$1;
    tmp$1 = result !== undefined && result.TAG !== "Ok" ? JsxRuntime.jsx("div", {
            children: result._0,
            className: css.errorMessage
          }) : null;
    tmp = JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsx("h4", {
                  children: "Geographical region",
                  className: css.geographicalRegionsTitle
                }),
            JsxRuntime.jsx("p", {
                  children: "Please, select whether locations in the document are USA only or global? it will help us to match proper providers",
                  className: css.geographicalRegionsDescription
                }),
            JsxRuntime.jsxs("div", {
                  children: [
                    JsxRuntime.jsxs(Select.make, {
                          id: domId$1,
                          value: form.input.geographicalRegion,
                          status: Belt_Option.map(form.geographicalRegionResult, (function (x) {
                                  if (x.TAG === "Ok") {
                                    return "Valid";
                                  } else {
                                    return "Error";
                                  }
                                })),
                          className: css.input,
                          wrapperClassName: css.selectWrapper,
                          iconSize: "XXS",
                          iconColor: "GrayText",
                          onChange: (function ($$event) {
                              var value = Belt_Option.getWithDefault(Belt_Array.getBy(options.geographicalRegions, (function (x) {
                                          return ReactForm.value($$event) === x;
                                        })), "");
                              form.updateGeographicalRegion((function (input, value) {
                                      return {
                                              configurations: input.configurations,
                                              documents: input.documents,
                                              deletedDocumentIds: input.deletedDocumentIds,
                                              geographicalRegion: value,
                                              validateRegion: input.validateRegion
                                            };
                                    }), value);
                            }),
                          onBlur: (function (param) {
                              form.blurGeographicalRegion();
                            }),
                          children: [
                            JsxRuntime.jsx(Select.DefaultOption.make, {
                                  children: "Select..."
                                }),
                            Belt_Array.map(options.geographicalRegions, (function (x) {
                                    return JsxRuntime.jsx(Select.$$Option.make, {
                                                value: x,
                                                children: x
                                              }, x);
                                  }))
                          ]
                        }),
                    tmp$1
                  ],
                  className: css.field
                })
          ],
          className: css.geographicalRegionsSection
        });
  } else {
    tmp = null;
  }
  var match$2 = state.userLoginStatus;
  var tmp$2;
  if (match$2 === "LoggedIn") {
    var tmp$3;
    tmp$3 = context.TAG === "Draft" ? "Next" : "Save and Next";
    var match$3 = form.status;
    var tmp$4;
    tmp$4 = typeof match$3 !== "object" || match$3.TAG !== "Submitting" ? "Enabled" : "Busy";
    tmp$2 = [
      tmp$3,
      (function () {
          var isRegionEmpty = !isRegionFieldVisible(form.input.documents, alreadyUploadedDocuments, form.input.deletedDocumentIds);
          if (isRegionEmpty) {
            form.updateGeographicalRegion((function (input, param) {
                    return {
                            configurations: input.configurations,
                            documents: input.documents,
                            deletedDocumentIds: input.deletedDocumentIds,
                            geographicalRegion: "",
                            validateRegion: input.validateRegion
                          };
                  }), "");
          }
          form.submit();
        }),
      tmp$4
    ];
  } else {
    tmp$2 = [
      "Next",
      (function () {
          toggleSignInModal(function (param) {
                return Visibility.toggle(signInModal);
              });
        }),
      "Enabled"
    ];
  }
  var match$4 = state.userLoginStatus;
  var tmp$5;
  tmp$5 = match$4 === "LoggedIn" && props.projectStatus === "Draft" ? [
      "Save and Continue Later",
      (function () {
          saveDraftProjectAndContinueLater(form, context);
        })
    ] : undefined;
  var tmp$6;
  tmp$6 = signInModal === "Shown" ? JsxRuntime.jsx(SignInModalContainer.make, {
          close: (function () {
              toggleSignInModal(function (param) {
                    return Visibility.toggle(signInModal);
                  });
            }),
          callback: (function () {
              dispatch({
                    TAG: "ToggleLoginStatus",
                    _0: "LoggedIn"
                  });
              Url.visit(Routes_Project.Network.SdWan.$$new);
            }),
          setUserData: props.setUserData,
          subTitle: "Sign In to save the project detail and continue later"
        }) : null;
  return JsxRuntime.jsxs(ProjectWizard.Container.make, {
              children: [
                JsxRuntime.jsxs(ProjectWizard.Content.make, {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("h1", {
                                    children: "Step 1: Configure Your SD-WAN Solution",
                                    className: css.title
                                  }),
                              className: css.tooltipAndHeadingWrapper
                            }),
                        JsxRuntime.jsx(ProjectWizard.H2.make, {
                              children: "Add Your Locations, Connections, and Features"
                            }),
                        JsxRuntime.jsx("div", {
                              children: "Please, add at least one location. If you have many locations, there is a 'Bulk Upload' button at the bottom where you can upload multiple documents about your SD-WAN network at once.",
                              className: css.subHeading
                            }),
                        JsxRuntime.jsx("div", {
                              children: Belt_Array.mapWithIndex(form.input.configurations, (function (configurationIndex, configuration) {
                                      var visibility = state.panelsVisibility[configurationIndex];
                                      var match = form.input.configurations;
                                      var tmp;
                                      if (visibility === "Shown") {
                                        var domId$2 = domId("start-date", configurationIndex);
                                        var result = form.configurationStartDateResult(configurationIndex);
                                        var tmp$1;
                                        tmp$1 = result !== undefined && result.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$2;
                                        tmp$2 = result !== undefined && result.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$3 = domId("contract-length", configurationIndex);
                                        var result$1 = form.configurationContractLengthResult(configurationIndex);
                                        var tmp$3;
                                        tmp$3 = result$1 !== undefined && result$1.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$4;
                                        tmp$4 = result$1 !== undefined && result$1.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$1._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$4 = domId("street-address", configurationIndex);
                                        var result$2 = form.configurationStreetAddressResult(configurationIndex);
                                        var tmp$5;
                                        tmp$5 = result$2 !== undefined && result$2.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$6;
                                        tmp$6 = result$2 !== undefined && result$2.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$2._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$5 = domId("building-or-floor", configurationIndex);
                                        var result$3 = form.configurationBuildingOrFloorResult(configurationIndex);
                                        var tmp$7;
                                        tmp$7 = result$3 !== undefined && result$3.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$8;
                                        tmp$8 = result$3 !== undefined && result$3.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$3._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$6 = domId("suite", configurationIndex);
                                        var result$4 = form.configurationSuiteResult(configurationIndex);
                                        var tmp$9;
                                        tmp$9 = result$4 !== undefined && result$4.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$10;
                                        tmp$10 = result$4 !== undefined && result$4.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$4._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$7 = domId("city-or-town", configurationIndex);
                                        var result$5 = form.configurationCityOrTownResult(configurationIndex);
                                        var tmp$11;
                                        tmp$11 = result$5 !== undefined && result$5.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$12;
                                        tmp$12 = result$5 !== undefined && result$5.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$5._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$8 = domId("sub-locality", configurationIndex);
                                        var result$6 = form.configurationSubLocalityResult(configurationIndex);
                                        var tmp$13;
                                        tmp$13 = result$6 !== undefined && result$6.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$14;
                                        tmp$14 = result$6 !== undefined && result$6.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$6._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$9 = domId("state-or-province", configurationIndex);
                                        var result$7 = form.configurationStateOrProvinceResult(configurationIndex);
                                        var tmp$15;
                                        tmp$15 = result$7 !== undefined && result$7.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$16;
                                        tmp$16 = result$7 !== undefined && result$7.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$7._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$10 = domId("country", configurationIndex);
                                        var result$8 = form.configurationCountryResult(configurationIndex);
                                        var tmp$17;
                                        tmp$17 = result$8 !== undefined && result$8.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$18;
                                        tmp$18 = result$8 !== undefined && result$8.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$8._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$11 = domId("postal-code", configurationIndex);
                                        var result$9 = form.configurationPostalCodeResult(configurationIndex);
                                        var tmp$19;
                                        tmp$19 = result$9 !== undefined && result$9.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$20;
                                        tmp$20 = result$9 !== undefined && result$9.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$9._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$12 = domId("prefix", configurationIndex);
                                        var result$10 = form.configurationPrefixResult(configurationIndex);
                                        var tmp$21;
                                        tmp$21 = result$10 !== undefined && result$10.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$22;
                                        tmp$22 = result$10 !== undefined && result$10.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$10._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$13 = domId("additional-location-details", configurationIndex);
                                        var result$11 = form.configurationAdditionalLocationDetailsResult(configurationIndex);
                                        var tmp$23;
                                        tmp$23 = result$11 !== undefined && result$11.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$24;
                                        tmp$24 = result$11 !== undefined && result$11.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$11._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$14 = domId("primary-network-type", configurationIndex);
                                        var result$12 = form.configurationPrimaryNetworkTypeResult(configurationIndex);
                                        var tmp$25;
                                        tmp$25 = result$12 !== undefined && result$12.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$26;
                                        tmp$26 = result$12 !== undefined && result$12.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$12._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var valueDomId = domId("primary-bandwidth-value", configurationIndex);
                                        var unitDomId = domId("primary-bandwidth-unit", configurationIndex);
                                        var valueResult = form.configurationPrimaryBandwidthValueResult(configurationIndex);
                                        var unitResult = form.configurationPrimaryBandwidthUnitResult(configurationIndex);
                                        var tmp$27;
                                        var exit = 0;
                                        if (valueResult !== undefined) {
                                          if (valueResult.TAG === "Ok") {
                                            exit = 1;
                                          } else {
                                            tmp$27 = {
                                              TAG: "Error",
                                              _0: undefined
                                            };
                                          }
                                        } else if (unitResult !== undefined) {
                                          exit = 1;
                                        } else {
                                          tmp$27 = undefined;
                                        }
                                        if (exit === 1) {
                                          tmp$27 = unitResult !== undefined && unitResult.TAG !== "Ok" ? ({
                                                TAG: "Error",
                                                _0: undefined
                                              }) : undefined;
                                        }
                                        var tmp$28;
                                        var exit$1 = 0;
                                        if (valueResult !== undefined) {
                                          if (valueResult.TAG === "Ok") {
                                            exit$1 = 1;
                                          } else {
                                            tmp$28 = css.errorLabel;
                                          }
                                        } else if (unitResult !== undefined) {
                                          exit$1 = 1;
                                        } else {
                                          tmp$28 = "";
                                        }
                                        if (exit$1 === 1) {
                                          tmp$28 = unitResult !== undefined && unitResult.TAG !== "Ok" ? css.errorLabel : "";
                                        }
                                        var tmp$29;
                                        tmp$29 = valueResult !== undefined && valueResult.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: valueResult._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var tmp$30;
                                        tmp$30 = unitResult !== undefined && unitResult.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: unitResult._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$15 = domId("primary-class-of-service", configurationIndex);
                                        var result$13 = form.configurationPrimaryClassOfServiceResult(configurationIndex);
                                        var tmp$31;
                                        tmp$31 = result$13 !== undefined && result$13.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$32;
                                        tmp$32 = result$13 !== undefined && result$13.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$13._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var visibility$1 = state.diverseNetworkConnectionVisibility[configurationIndex];
                                        var tmp$33;
                                        if (visibility$1 === "Shown") {
                                          var domId$16 = domId("diverse-network-type", configurationIndex);
                                          var result$14 = form.configurationDiverseNetworkOptionResult(configurationIndex);
                                          var tmp$34;
                                          tmp$34 = result$14 !== undefined && result$14.TAG !== "Ok" ? css.errorLabel : "";
                                          var tmp$35;
                                          tmp$35 = result$14 !== undefined && result$14.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                  children: result$14._0,
                                                  className: css.errorMessage
                                                }) : null;
                                          var valueDomId$1 = domId("diverse-bandwidth-value", configurationIndex);
                                          var unitDomId$1 = domId("diverse-bandwidth-unit", configurationIndex);
                                          var valueResult$1 = form.configurationDiverseBandwidthValueResult(configurationIndex);
                                          var unitResult$1 = form.configurationDiverseBandwidthUnitResult(configurationIndex);
                                          var tmp$36;
                                          var exit$2 = 0;
                                          if (valueResult$1 !== undefined) {
                                            if (valueResult$1.TAG === "Ok") {
                                              exit$2 = 1;
                                            } else {
                                              tmp$36 = {
                                                TAG: "Error",
                                                _0: undefined
                                              };
                                            }
                                          } else if (unitResult$1 !== undefined) {
                                            exit$2 = 1;
                                          } else {
                                            tmp$36 = undefined;
                                          }
                                          if (exit$2 === 1) {
                                            tmp$36 = unitResult$1 !== undefined && unitResult$1.TAG !== "Ok" ? ({
                                                  TAG: "Error",
                                                  _0: undefined
                                                }) : undefined;
                                          }
                                          var tmp$37;
                                          var exit$3 = 0;
                                          if (valueResult$1 !== undefined) {
                                            if (valueResult$1.TAG === "Ok") {
                                              exit$3 = 1;
                                            } else {
                                              tmp$37 = css.errorLabel;
                                            }
                                          } else if (unitResult$1 !== undefined) {
                                            exit$3 = 1;
                                          } else {
                                            tmp$37 = "";
                                          }
                                          if (exit$3 === 1) {
                                            tmp$37 = unitResult$1 !== undefined && unitResult$1.TAG !== "Ok" ? css.errorLabel : "";
                                          }
                                          var tmp$38;
                                          tmp$38 = valueResult$1 !== undefined && valueResult$1.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                  children: valueResult$1._0,
                                                  className: css.errorMessage
                                                }) : null;
                                          var tmp$39;
                                          tmp$39 = unitResult$1 !== undefined && unitResult$1.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                  children: unitResult$1._0,
                                                  className: css.errorMessage
                                                }) : null;
                                          var domId$17 = domId("diverse-class-of-service", configurationIndex);
                                          var result$15 = form.configurationDiverseClassOfServiceResult(configurationIndex);
                                          var tmp$40;
                                          tmp$40 = result$15 !== undefined && result$15.TAG !== "Ok" ? css.errorLabel : "";
                                          var tmp$41;
                                          tmp$41 = result$15 !== undefined && result$15.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                  children: result$15._0,
                                                  className: css.errorMessage
                                                }) : null;
                                          tmp$33 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                children: [
                                                  JsxRuntime.jsxs("div", {
                                                        children: [
                                                          JsxRuntime.jsxs("div", {
                                                                children: [
                                                                  JsxRuntime.jsx(Label.make, {
                                                                        forId: domId$16,
                                                                        status: result$14,
                                                                        className: Cx.cx([
                                                                              css.label,
                                                                              tmp$34
                                                                            ]),
                                                                        children: "Diverse Network Option"
                                                                      }),
                                                                  JsxRuntime.jsxs(Select.make, {
                                                                        id: domId$16,
                                                                        value: configuration.diverseNetworkOption,
                                                                        status: Belt_Option.map(result$14, (function (x) {
                                                                                if (x.TAG === "Ok") {
                                                                                  return "Valid";
                                                                                } else {
                                                                                  return "Error";
                                                                                }
                                                                              })),
                                                                        className: css.input,
                                                                        wrapperClassName: css.selectWrapper,
                                                                        iconSize: "XXS",
                                                                        iconColor: "GrayText",
                                                                        onChange: (function ($$event) {
                                                                            var value = Belt_Option.getWithDefault(Belt_Array.getBy(options.diverseNetworkOptions, (function (x) {
                                                                                        return ReactForm.value($$event) === x;
                                                                                      })), "");
                                                                            var match = fromString(value);
                                                                            var exit = 0;
                                                                            if (match !== "Mpls") {
                                                                              exit = 1;
                                                                            }
                                                                            if (exit === 1) {
                                                                              form.updateConfigurationDiverseClassOfService(configurationIndex, (function (input, classOfServie) {
                                                                                      return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                    var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                    newrecord.diverseClassOfService = classOfServie;
                                                                                                    return newrecord;
                                                                                                  }));
                                                                                    }), "");
                                                                            }
                                                                            form.updateConfigurationDiverseNetworkOption(configurationIndex, (function (input, value) {
                                                                                    return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                  var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                  newrecord.diverseNetworkOption = value;
                                                                                                  return newrecord;
                                                                                                }));
                                                                                  }), value);
                                                                            persistDraft([
                                                                                  CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                          var newrecord = Caml_obj.obj_dup(configuration);
                                                                                          newrecord.diverseNetworkOption = value;
                                                                                          return newrecord;
                                                                                        })),
                                                                                  context
                                                                                ]);
                                                                          }),
                                                                        onBlur: (function (param) {
                                                                            form.blurConfigurationDiverseNetworkOption(configurationIndex);
                                                                          }),
                                                                        children: [
                                                                          JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                                selectable: true,
                                                                                children: ""
                                                                              }),
                                                                          Belt_Array.map(options.diverseNetworkOptions, (function (x) {
                                                                                  return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                              value: x,
                                                                                              children: x
                                                                                            }, x);
                                                                                }))
                                                                        ]
                                                                      }),
                                                                  tmp$35
                                                                ],
                                                                className: css.field
                                                              }),
                                                          JsxRuntime.jsxs("div", {
                                                                children: [
                                                                  JsxRuntime.jsx(Label.make, {
                                                                        forId: valueDomId$1,
                                                                        status: tmp$36,
                                                                        className: Cx.cx([
                                                                              css.label,
                                                                              tmp$37
                                                                            ]),
                                                                        children: "Bandwidth:"
                                                                      }),
                                                                  JsxRuntime.jsxs("div", {
                                                                        children: [
                                                                          JsxRuntime.jsxs("div", {
                                                                                children: [
                                                                                  JsxRuntime.jsx(TextField.make, {
                                                                                        id: valueDomId$1,
                                                                                        value: Belt_Option.mapWithDefault(configuration.diverseBandwidthValue, "", (function (prim) {
                                                                                                return String(prim);
                                                                                              })),
                                                                                        status: Belt_Option.flatMap(valueResult$1, (function (x) {
                                                                                                if (x.TAG === "Ok") {
                                                                                                  if (x._0 !== undefined) {
                                                                                                    return "Valid";
                                                                                                  } else {
                                                                                                    return ;
                                                                                                  }
                                                                                                } else {
                                                                                                  return "Error";
                                                                                                }
                                                                                              })),
                                                                                        className: Cx.cx([
                                                                                              css.input,
                                                                                              css.inputWithUnitValue
                                                                                            ]),
                                                                                        onBlur: (function (param) {
                                                                                            form.blurConfigurationDiverseBandwidthValue(configurationIndex);
                                                                                          }),
                                                                                        onChange: (function ($$event) {
                                                                                            var x = ReactForm.value($$event);
                                                                                            var value;
                                                                                            if (x === "") {
                                                                                              value = {
                                                                                                TAG: "Ok",
                                                                                                _0: undefined
                                                                                              };
                                                                                            } else {
                                                                                              var n = Belt_Int.fromString(x);
                                                                                              value = n !== undefined && String(n) === x ? ({
                                                                                                    TAG: "Ok",
                                                                                                    _0: n
                                                                                                  }) : ({
                                                                                                    TAG: "Error",
                                                                                                    _0: undefined
                                                                                                  });
                                                                                            }
                                                                                            if (value.TAG !== "Ok") {
                                                                                              return ;
                                                                                            }
                                                                                            var value$1 = value._0;
                                                                                            form.updateConfigurationDiverseBandwidthValue(configurationIndex, (function (input, value) {
                                                                                                    return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                                  var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                                  newrecord.diverseBandwidthValue = value;
                                                                                                                  return newrecord;
                                                                                                                }));
                                                                                                  }), value$1);
                                                                                            persistDraft([
                                                                                                  CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                          var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                          newrecord.diverseBandwidthValue = value$1;
                                                                                                          return newrecord;
                                                                                                        })),
                                                                                                  context
                                                                                                ]);
                                                                                          })
                                                                                      }),
                                                                                  tmp$38
                                                                                ],
                                                                                className: css.inputWithUnitValueContainer
                                                                              }),
                                                                          JsxRuntime.jsxs("div", {
                                                                                children: [
                                                                                  JsxRuntime.jsxs(Select.make, {
                                                                                        id: unitDomId$1,
                                                                                        value: configuration.diverseBandwidthUnit,
                                                                                        status: Belt_Option.map(unitResult$1, (function (x) {
                                                                                                if (x.TAG === "Ok") {
                                                                                                  return "Valid";
                                                                                                } else {
                                                                                                  return "Error";
                                                                                                }
                                                                                              })),
                                                                                        className: Cx.cx([
                                                                                              css.input,
                                                                                              css.inputWithUnitUnit
                                                                                            ]),
                                                                                        wrapperClassName: css.unitSelectWrapper,
                                                                                        iconSize: "XXS",
                                                                                        iconColor: "GrayText",
                                                                                        onChange: (function ($$event) {
                                                                                            var value = Belt_Option.getWithDefault(Belt_Array.getBy(options.diverseBandwidthUnits, (function (x) {
                                                                                                        return ReactForm.value($$event) === x;
                                                                                                      })), "");
                                                                                            form.updateConfigurationDiverseBandwidthUnit(configurationIndex, (function (input, value) {
                                                                                                    return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                                  var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                                  newrecord.diverseBandwidthUnit = value;
                                                                                                                  return newrecord;
                                                                                                                }));
                                                                                                  }), value);
                                                                                            persistDraft([
                                                                                                  CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                          var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                          newrecord.diverseBandwidthUnit = value;
                                                                                                          return newrecord;
                                                                                                        })),
                                                                                                  context
                                                                                                ]);
                                                                                          }),
                                                                                        onBlur: (function (param) {
                                                                                            form.blurConfigurationDiverseBandwidthUnit(configurationIndex);
                                                                                          }),
                                                                                        children: [
                                                                                          JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                                                selectable: true,
                                                                                                children: ""
                                                                                              }),
                                                                                          Belt_Array.map(options.diverseBandwidthUnits, (function (x) {
                                                                                                  return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                                              value: x,
                                                                                                              children: x
                                                                                                            }, x);
                                                                                                }))
                                                                                        ]
                                                                                      }),
                                                                                  tmp$39
                                                                                ],
                                                                                className: css.inputWithUnitUnitContainer
                                                                              })
                                                                        ],
                                                                        className: css.fieldWithUnit
                                                                      })
                                                                ],
                                                                className: css.field
                                                              }),
                                                          JsxRuntime.jsxs("div", {
                                                                children: [
                                                                  JsxRuntime.jsx(Label.make, {
                                                                        forId: domId$17,
                                                                        status: result$15,
                                                                        className: Cx.cx([
                                                                              css.label,
                                                                              tmp$40
                                                                            ]),
                                                                        children: "Class of Service:"
                                                                      }),
                                                                  JsxRuntime.jsxs(Select.make, {
                                                                        id: domId$17,
                                                                        value: configuration.diverseClassOfService,
                                                                        disabled: configuration.diverseNetworkOption !== "MPLS",
                                                                        status: Belt_Option.map(result$15, (function (x) {
                                                                                if (x.TAG === "Ok") {
                                                                                  return "Valid";
                                                                                } else {
                                                                                  return "Error";
                                                                                }
                                                                              })),
                                                                        className: css.input,
                                                                        wrapperClassName: css.selectWrapper,
                                                                        iconSize: "XXS",
                                                                        iconColor: "GrayText",
                                                                        onChange: (function ($$event) {
                                                                            var value = Belt_Option.getWithDefault(Belt_Array.getBy(options.diverseClassOfServices, (function (x) {
                                                                                        return ReactForm.value($$event) === x;
                                                                                      })), "");
                                                                            form.updateConfigurationDiverseClassOfService(configurationIndex, (function (input, value) {
                                                                                    return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                  var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                  newrecord.diverseClassOfService = value;
                                                                                                  return newrecord;
                                                                                                }));
                                                                                  }), value);
                                                                            persistDraft([
                                                                                  CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                          var newrecord = Caml_obj.obj_dup(configuration);
                                                                                          newrecord.diverseClassOfService = value;
                                                                                          return newrecord;
                                                                                        })),
                                                                                  context
                                                                                ]);
                                                                          }),
                                                                        onBlur: (function (param) {
                                                                            form.blurConfigurationDiverseClassOfService(configurationIndex);
                                                                          }),
                                                                        children: [
                                                                          JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                                selectable: true,
                                                                                children: "Select..."
                                                                              }),
                                                                          Belt_Array.map(options.diverseClassOfServices, (function (x) {
                                                                                  return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                              value: x,
                                                                                              children: x
                                                                                            }, x);
                                                                                }))
                                                                        ]
                                                                      }),
                                                                  tmp$41
                                                                ],
                                                                className: css.field
                                                              })
                                                        ],
                                                        className: Cx.cx([
                                                              css.fieldRow,
                                                              css.unfilled
                                                            ])
                                                      }),
                                                  JsxRuntime.jsx("div", {
                                                        children: JsxRuntime.jsx(ProjectWizardPanel.ZebraGrid.make, {
                                                              items: options.diverseServices,
                                                              mobile: mobile,
                                                              children: (function (option) {
                                                                  var domId$18 = domId("diverse-services" + option, configurationIndex);
                                                                  return JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                                                              id: domId$18,
                                                                              size: "MD",
                                                                              checked: Belt_SetString.has(configuration.diverseServices, option),
                                                                              containerClassName: css.checkbox,
                                                                              onChange: (function ($$event) {
                                                                                  var value = ReactForm.checked($$event) ? Belt_SetString.add(configuration.diverseServices, option) : Belt_SetString.remove(configuration.diverseServices, option);
                                                                                  form.updateConfigurationDiverseServices(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                        newrecord.diverseServices = value;
                                                                                                        return newrecord;
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                newrecord.diverseServices = value;
                                                                                                return newrecord;
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                }),
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationDiverseServices(configurationIndex);
                                                                                }),
                                                                              children: option
                                                                            }, domId$18);
                                                                })
                                                            }),
                                                        className: Cx.cx([
                                                              css.field,
                                                              css.expanded
                                                            ])
                                                      })
                                                ]
                                              });
                                        } else {
                                          tmp$33 = null;
                                        }
                                        var visibility$2 = state.sdWanFeaturesVisibility[configurationIndex];
                                        var tmp$42;
                                        tmp$42 = visibility$2 === "Shown" ? JsxRuntime.jsx(ProjectWizardPanel.ZebraGrid.make, {
                                                items: options.sdWanFeatures,
                                                mobile: mobile,
                                                children: (function (option) {
                                                    var domId$18 = domId("network-application-backup-" + option, configurationIndex);
                                                    return JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                                                id: domId$18,
                                                                size: "MD",
                                                                checked: Belt_SetString.has(configuration.sdWanFeatures, option),
                                                                containerClassName: css.checkbox,
                                                                onChange: (function ($$event) {
                                                                    var value = ReactForm.checked($$event) ? Belt_SetString.add(configuration.sdWanFeatures, option) : Belt_SetString.remove(configuration.sdWanFeatures, option);
                                                                    form.updateConfigurationSdWanFeatures(configurationIndex, (function (input, value) {
                                                                            return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                          var newrecord = Caml_obj.obj_dup(configuration);
                                                                                          newrecord.sdWanFeatures = value;
                                                                                          return newrecord;
                                                                                        }));
                                                                          }), value);
                                                                    persistDraft([
                                                                          CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                  var newrecord = Caml_obj.obj_dup(configuration);
                                                                                  newrecord.sdWanFeatures = value;
                                                                                  return newrecord;
                                                                                })),
                                                                          context
                                                                        ]);
                                                                  }),
                                                                onBlur: (function (param) {
                                                                    form.blurConfigurationSdWanFeatures(configurationIndex);
                                                                  }),
                                                                children: option
                                                              }, domId$18);
                                                  })
                                              }) : null;
                                        var visibility$3 = state.additionalRequirementsVisibility[configurationIndex];
                                        var tmp$43;
                                        if (visibility$3 === "Shown") {
                                          var domId$18 = domId("additional-requirements", configurationIndex);
                                          var result$16 = form.configurationAdditionalRequirementsResult(configurationIndex);
                                          var tmp$44;
                                          tmp$44 = result$16 !== undefined && result$16.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                  children: result$16._0,
                                                  className: css.errorMessage
                                                }) : null;
                                          tmp$43 = JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx(Textarea.make, {
                                                        id: domId$18,
                                                        value: configuration.additionalRequirements,
                                                        status: Belt_Option.flatMap(result$16, (function (x) {
                                                                if (x.TAG === "Ok") {
                                                                  if (x._0 !== undefined) {
                                                                    return "Valid";
                                                                  } else {
                                                                    return ;
                                                                  }
                                                                } else {
                                                                  return "Error";
                                                                }
                                                              })),
                                                        wrapperClassName: css.textarea,
                                                        onBlur: (function (param) {
                                                            form.blurConfigurationAdditionalRequirements(configurationIndex);
                                                          }),
                                                        onChange: (function ($$event) {
                                                            var value = ReactForm.value($$event);
                                                            form.updateConfigurationAdditionalRequirements(configurationIndex, (function (input, value) {
                                                                    return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                  var newrecord = Caml_obj.obj_dup(configuration);
                                                                                  newrecord.additionalRequirements = value;
                                                                                  return newrecord;
                                                                                }));
                                                                  }), value);
                                                            persistDraft([
                                                                  CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                          var newrecord = Caml_obj.obj_dup(configuration);
                                                                          newrecord.additionalRequirements = value;
                                                                          return newrecord;
                                                                        })),
                                                                  context
                                                                ]);
                                                          })
                                                      }),
                                                  tmp$44
                                                ],
                                                className: Cx.cx([
                                                      css.fieldRow,
                                                      css.justified
                                                    ])
                                              });
                                        } else {
                                          tmp$43 = null;
                                        }
                                        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                              children: [
                                                JsxRuntime.jsx(ProjectWizardPanel.HeadlessSection.make, {
                                                      children: JsxRuntime.jsxs("div", {
                                                            children: [
                                                              JsxRuntime.jsxs("div", {
                                                                    children: [
                                                                      JsxRuntime.jsx(Label.make, {
                                                                            forId: domId$2,
                                                                            status: result,
                                                                            className: Cx.cx([
                                                                                  css.label,
                                                                                  css.requiredLabel,
                                                                                  tmp$1
                                                                                ]),
                                                                            children: "Requested Install Date"
                                                                          }),
                                                                      JsxRuntime.jsx(DatePicker.make, {
                                                                            id: domId$2,
                                                                            value: Belt_Option.map(configuration.startDate, (function (prim) {
                                                                                    return prim;
                                                                                  })),
                                                                            fromMonth: Caml_option.some(AddDays(new Date(), 1)),
                                                                            disabledDays: [{
                                                                                from: new Date(0, 0),
                                                                                to: new Date()
                                                                              }],
                                                                            status: Belt_Option.map(result, (function (x) {
                                                                                    if (x.TAG === "Ok") {
                                                                                      return "Valid";
                                                                                    } else {
                                                                                      return "Error";
                                                                                    }
                                                                                  })),
                                                                            onDayChange: (function (date, param, param$1) {
                                                                                form.updateConfigurationStartDate(configurationIndex, (function (input, value) {
                                                                                        return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                      var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                      newrecord.startDate = value;
                                                                                                      return newrecord;
                                                                                                    }));
                                                                                      }), Caml_option.some(date));
                                                                                persistDraft([
                                                                                      CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                              var newrecord = Caml_obj.obj_dup(configuration);
                                                                                              newrecord.startDate = Caml_option.some(date);
                                                                                              return newrecord;
                                                                                            })),
                                                                                      context
                                                                                    ]);
                                                                              }),
                                                                            onHide: (function () {
                                                                                form.blurConfigurationStartDate(configurationIndex);
                                                                              }),
                                                                            className: css.input
                                                                          }),
                                                                      tmp$2
                                                                    ],
                                                                    className: css.field
                                                                  }),
                                                              JsxRuntime.jsxs("div", {
                                                                    children: [
                                                                      JsxRuntime.jsx(Label.make, {
                                                                            forId: domId$3,
                                                                            status: result$1,
                                                                            className: Cx.cx([
                                                                                  css.label,
                                                                                  css.requiredLabel,
                                                                                  tmp$3
                                                                                ]),
                                                                            children: "Contract Length"
                                                                          }),
                                                                      JsxRuntime.jsxs(Select.make, {
                                                                            id: domId$3,
                                                                            value: Belt_Option.mapWithDefault(configuration.contractLength, "", (function (cl) {
                                                                                    return String(cl.value);
                                                                                  })),
                                                                            status: Belt_Option.map(result$1, (function (x) {
                                                                                    if (x.TAG === "Ok") {
                                                                                      return "Valid";
                                                                                    } else {
                                                                                      return "Error";
                                                                                    }
                                                                                  })),
                                                                            className: css.input,
                                                                            wrapperClassName: css.selectWrapper,
                                                                            iconSize: "XXS",
                                                                            iconColor: "GrayText",
                                                                            onChange: (function ($$event) {
                                                                                var value = Belt_Array.getBy(options.contractLengths, (function (cl) {
                                                                                        return Belt_Option.mapWithDefault(Belt_Int.fromString(ReactForm.value($$event)), false, (function (value) {
                                                                                                      return value === cl.value;
                                                                                                    }));
                                                                                      }));
                                                                                form.updateConfigurationContractLength(configurationIndex, (function (input, value) {
                                                                                        return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                      var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                      newrecord.contractLength = value;
                                                                                                      return newrecord;
                                                                                                    }));
                                                                                      }), value);
                                                                                persistDraft([
                                                                                      CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                              var newrecord = Caml_obj.obj_dup(configuration);
                                                                                              newrecord.contractLength = value;
                                                                                              return newrecord;
                                                                                            })),
                                                                                      context
                                                                                    ]);
                                                                              }),
                                                                            onBlur: (function (param) {
                                                                                form.blurConfigurationContractLength(configurationIndex);
                                                                              }),
                                                                            children: [
                                                                              JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                                    children: "Select..."
                                                                                  }),
                                                                              Belt_Array.map(options.contractLengths, (function (cl) {
                                                                                      return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                                  value: String(cl.value),
                                                                                                  children: cl.label
                                                                                                }, String(cl.value));
                                                                                    }))
                                                                            ]
                                                                          }),
                                                                      tmp$4
                                                                    ],
                                                                    className: css.field
                                                                  })
                                                            ],
                                                            className: Cx.cx([
                                                                  css.fieldRow,
                                                                  css.unfilled
                                                                ])
                                                          })
                                                    }),
                                                JsxRuntime.jsxs(ProjectWizardPanel.Section.make, {
                                                      title: "Location",
                                                      children: [
                                                        JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$4,
                                                                              status: result$2,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    css.requiredLabel,
                                                                                    tmp$5
                                                                                  ]),
                                                                              children: "Street Address:"
                                                                            }),
                                                                        JsxRuntime.jsx(ReactGeoSuggestField.make, {
                                                                              id: domId$4,
                                                                              value: configuration.streetAddress,
                                                                              radius: "20",
                                                                              status: Belt_Option.map(result$2, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              location: new (google.maps.LatLng)(53.558572, 9.9278215),
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationStreetAddress(configurationIndex);
                                                                                }),
                                                                              onChange: (function (value) {
                                                                                  form.updateConfigurationStreetAddress(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                        newrecord.streetAddress = value;
                                                                                                        return newrecord;
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                newrecord.streetAddress = value;
                                                                                                return newrecord;
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                }),
                                                                              onSuggestSelect: (function (suggest) {
                                                                                  var result = ReactGeoSuggest.ReactGeoSuggestData.fromJson(suggest);
                                                                                  var gmaps = result.gmaps;
                                                                                  if (gmaps === undefined) {
                                                                                    return ;
                                                                                  }
                                                                                  var addresses = gmaps.address_components;
                                                                                  var cityOrTown = {
                                                                                    contents: configuration.cityOrTown
                                                                                  };
                                                                                  var stateOrProvince = {
                                                                                    contents: configuration.stateOrProvince
                                                                                  };
                                                                                  var country = {
                                                                                    contents: configuration.country
                                                                                  };
                                                                                  var postalCode = {
                                                                                    contents: configuration.postalCode
                                                                                  };
                                                                                  Belt_Array.forEach(addresses, (function (address) {
                                                                                          var addressType = Belt_Array.get(address.types, 0);
                                                                                          if (addressType !== undefined) {
                                                                                            if (addressType === "locality" || addressType === "postal_town") {
                                                                                              cityOrTown.contents = address.long_name;
                                                                                            }
                                                                                            if (addressType === "administrative_area_level_1") {
                                                                                              stateOrProvince.contents = address.long_name;
                                                                                            }
                                                                                            if (addressType === "country") {
                                                                                              country.contents = address.long_name;
                                                                                            }
                                                                                            if (addressType === "postal_code") {
                                                                                              postalCode.contents = address.long_name;
                                                                                              return ;
                                                                                            } else {
                                                                                              return ;
                                                                                            }
                                                                                          }
                                                                                          
                                                                                        }));
                                                                                  form.updateConfigurationStreetAddress(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                        newrecord.streetAddress = value;
                                                                                                        return newrecord;
                                                                                                      }));
                                                                                        }), gmaps.name);
                                                                                  form.updateConfigurationCityOrTown(configurationIndex, (function (input, cityOrTown) {
                                                                                          return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                        newrecord.cityOrTown = cityOrTown;
                                                                                                        return newrecord;
                                                                                                      }));
                                                                                        }), cityOrTown.contents);
                                                                                  form.updateConfigurationStateOrProvince(configurationIndex, (function (input, stateOrProvince) {
                                                                                          return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                        newrecord.stateOrProvince = stateOrProvince;
                                                                                                        return newrecord;
                                                                                                      }));
                                                                                        }), stateOrProvince.contents);
                                                                                  form.updateConfigurationCountry(configurationIndex, (function (input, country) {
                                                                                          return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                        newrecord.country = country;
                                                                                                        return newrecord;
                                                                                                      }));
                                                                                        }), country.contents);
                                                                                  form.updateConfigurationPostalCode(configurationIndex, (function (input, postalCode) {
                                                                                          return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                        newrecord.postalCode = postalCode;
                                                                                                        return newrecord;
                                                                                                      }));
                                                                                        }), postalCode.contents);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                newrecord.postalCode = postalCode.contents;
                                                                                                newrecord.country = country.contents;
                                                                                                newrecord.stateOrProvince = stateOrProvince.contents;
                                                                                                newrecord.cityOrTown = cityOrTown.contents;
                                                                                                newrecord.streetAddress = gmaps.name;
                                                                                                return newrecord;
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                })
                                                                            }),
                                                                        tmp$6
                                                                      ],
                                                                      className: Cx.cx([
                                                                            css.field,
                                                                            css.elongated
                                                                          ])
                                                                    }),
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$5,
                                                                              status: result$3,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    tmp$7
                                                                                  ]),
                                                                              children: "Building/Floor:"
                                                                            }),
                                                                        JsxRuntime.jsx(TextField.make, {
                                                                              id: domId$5,
                                                                              value: configuration.buildingOrFloor,
                                                                              status: Belt_Option.flatMap(result$3, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        if (x._0 !== undefined) {
                                                                                          return "Valid";
                                                                                        } else {
                                                                                          return ;
                                                                                        }
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationBuildingOrFloor(configurationIndex);
                                                                                }),
                                                                              onChange: (function ($$event) {
                                                                                  var value = ReactForm.value($$event);
                                                                                  form.updateConfigurationBuildingOrFloor(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                        newrecord.buildingOrFloor = value;
                                                                                                        return newrecord;
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                newrecord.buildingOrFloor = value;
                                                                                                return newrecord;
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                })
                                                                            }),
                                                                        tmp$8
                                                                      ],
                                                                      className: css.field
                                                                    }),
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$6,
                                                                              status: result$4,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    tmp$9
                                                                                  ]),
                                                                              children: "Suite:"
                                                                            }),
                                                                        JsxRuntime.jsx(TextField.make, {
                                                                              id: domId$6,
                                                                              value: configuration.suite,
                                                                              status: Belt_Option.flatMap(result$4, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        if (x._0 !== undefined) {
                                                                                          return "Valid";
                                                                                        } else {
                                                                                          return ;
                                                                                        }
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationSuite(configurationIndex);
                                                                                }),
                                                                              onChange: (function ($$event) {
                                                                                  var value = ReactForm.value($$event);
                                                                                  form.updateConfigurationSuite(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                        newrecord.suite = value;
                                                                                                        return newrecord;
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                newrecord.suite = value;
                                                                                                return newrecord;
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                })
                                                                            }),
                                                                        tmp$10
                                                                      ],
                                                                      className: css.field
                                                                    })
                                                              ],
                                                              className: Cx.cx([
                                                                    css.fieldRow,
                                                                    css.justified
                                                                  ])
                                                            }),
                                                        JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$7,
                                                                              status: result$5,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    css.requiredLabel,
                                                                                    tmp$11
                                                                                  ]),
                                                                              children: "City/Town:"
                                                                            }),
                                                                        JsxRuntime.jsx(TextField.make, {
                                                                              id: domId$7,
                                                                              value: configuration.cityOrTown,
                                                                              status: Belt_Option.map(result$5, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationCityOrTown(configurationIndex);
                                                                                }),
                                                                              onChange: (function ($$event) {
                                                                                  var value = ReactForm.value($$event);
                                                                                  form.updateConfigurationCityOrTown(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                        newrecord.cityOrTown = value;
                                                                                                        return newrecord;
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                newrecord.cityOrTown = value;
                                                                                                return newrecord;
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                })
                                                                            }),
                                                                        tmp$12
                                                                      ],
                                                                      className: Cx.cx([
                                                                            css.field,
                                                                            css.moderate
                                                                          ])
                                                                    }),
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$8,
                                                                              status: result$6,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    tmp$13
                                                                                  ]),
                                                                              children: "Sub-Locality:"
                                                                            }),
                                                                        JsxRuntime.jsx(TextField.make, {
                                                                              id: domId$8,
                                                                              value: configuration.subLocality,
                                                                              status: Belt_Option.flatMap(result$6, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        if (x._0 !== undefined) {
                                                                                          return "Valid";
                                                                                        } else {
                                                                                          return ;
                                                                                        }
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationSubLocality(configurationIndex);
                                                                                }),
                                                                              onChange: (function ($$event) {
                                                                                  var value = ReactForm.value($$event);
                                                                                  form.updateConfigurationSubLocality(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                        newrecord.subLocality = value;
                                                                                                        return newrecord;
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                newrecord.subLocality = value;
                                                                                                return newrecord;
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                })
                                                                            }),
                                                                        tmp$14
                                                                      ],
                                                                      className: Cx.cx([
                                                                            css.field,
                                                                            css.moderate
                                                                          ])
                                                                    }),
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$9,
                                                                              status: result$7,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    css.requiredLabel,
                                                                                    tmp$15
                                                                                  ]),
                                                                              children: "State/Province:"
                                                                            }),
                                                                        JsxRuntime.jsx(TextField.make, {
                                                                              id: domId$9,
                                                                              value: configuration.stateOrProvince,
                                                                              status: Belt_Option.map(result$7, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationStateOrProvince(configurationIndex);
                                                                                }),
                                                                              onChange: (function ($$event) {
                                                                                  var value = ReactForm.value($$event);
                                                                                  form.updateConfigurationStateOrProvince(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                        newrecord.stateOrProvince = value;
                                                                                                        return newrecord;
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                newrecord.stateOrProvince = value;
                                                                                                return newrecord;
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                })
                                                                            }),
                                                                        tmp$16
                                                                      ],
                                                                      className: css.field
                                                                    })
                                                              ],
                                                              className: Cx.cx([
                                                                    css.fieldRow,
                                                                    css.justified
                                                                  ])
                                                            }),
                                                        JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$10,
                                                                              status: result$8,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    css.requiredLabel,
                                                                                    tmp$17
                                                                                  ]),
                                                                              children: "Country:"
                                                                            }),
                                                                        JsxRuntime.jsx(TextField.make, {
                                                                              id: domId$10,
                                                                              value: configuration.country,
                                                                              status: Belt_Option.map(result$8, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationCountry(configurationIndex);
                                                                                }),
                                                                              onChange: (function ($$event) {
                                                                                  var value = ReactForm.value($$event);
                                                                                  form.updateConfigurationCountry(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                        newrecord.country = value;
                                                                                                        return newrecord;
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                newrecord.country = value;
                                                                                                return newrecord;
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                })
                                                                            }),
                                                                        tmp$18
                                                                      ],
                                                                      className: Cx.cx([
                                                                            css.field,
                                                                            css.moderate
                                                                          ])
                                                                    }),
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$11,
                                                                              status: result$9,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    css.requiredLabel,
                                                                                    tmp$19
                                                                                  ]),
                                                                              children: "Postal Code:"
                                                                            }),
                                                                        JsxRuntime.jsx(TextField.make, {
                                                                              id: domId$11,
                                                                              value: configuration.postalCode,
                                                                              status: Belt_Option.map(result$9, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationPostalCode(configurationIndex);
                                                                                }),
                                                                              onChange: (function ($$event) {
                                                                                  var value = ReactForm.value($$event);
                                                                                  form.updateConfigurationPostalCode(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                        newrecord.postalCode = value;
                                                                                                        return newrecord;
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                newrecord.postalCode = value;
                                                                                                return newrecord;
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                })
                                                                            }),
                                                                        tmp$20
                                                                      ],
                                                                      className: css.field
                                                                    }),
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$12,
                                                                              status: result$10,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    tmp$21
                                                                                  ]),
                                                                              children: "Prefix (NPA/NXX):"
                                                                            }),
                                                                        JsxRuntime.jsx(TextField.make, {
                                                                              id: domId$12,
                                                                              value: configuration.prefix,
                                                                              status: Belt_Option.flatMap(result$10, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        if (x._0 !== undefined) {
                                                                                          return "Valid";
                                                                                        } else {
                                                                                          return ;
                                                                                        }
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationPrefix(configurationIndex);
                                                                                }),
                                                                              onChange: (function ($$event) {
                                                                                  var value = ReactForm.value($$event);
                                                                                  form.updateConfigurationPrefix(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                        newrecord.prefix = value;
                                                                                                        return newrecord;
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                newrecord.prefix = value;
                                                                                                return newrecord;
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                })
                                                                            }),
                                                                        tmp$22
                                                                      ],
                                                                      className: css.field
                                                                    })
                                                              ],
                                                              className: Cx.cx([
                                                                    css.fieldRow,
                                                                    css.unfilled
                                                                  ])
                                                            }),
                                                        JsxRuntime.jsx("div", {
                                                              children: JsxRuntime.jsxs("div", {
                                                                    children: [
                                                                      JsxRuntime.jsx(Label.make, {
                                                                            forId: domId$13,
                                                                            status: result$11,
                                                                            className: Cx.cx([
                                                                                  css.label,
                                                                                  tmp$23
                                                                                ]),
                                                                            children: "Additional Location Details:"
                                                                          }),
                                                                      JsxRuntime.jsx(Textarea.make, {
                                                                            id: domId$13,
                                                                            value: configuration.additionalLocationDetails,
                                                                            rows: 3,
                                                                            status: Belt_Option.flatMap(result$11, (function (x) {
                                                                                    if (x.TAG === "Ok") {
                                                                                      if (x._0 !== undefined) {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return ;
                                                                                      }
                                                                                    } else {
                                                                                      return "Error";
                                                                                    }
                                                                                  })),
                                                                            className: css.input,
                                                                            onBlur: (function (param) {
                                                                                form.blurConfigurationAdditionalLocationDetails(configurationIndex);
                                                                              }),
                                                                            onChange: (function ($$event) {
                                                                                var value = ReactForm.value($$event);
                                                                                form.updateConfigurationAdditionalLocationDetails(configurationIndex, (function (input, value) {
                                                                                        return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                      var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                      newrecord.additionalLocationDetails = value;
                                                                                                      return newrecord;
                                                                                                    }));
                                                                                      }), value);
                                                                                persistDraft([
                                                                                      CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                              var newrecord = Caml_obj.obj_dup(configuration);
                                                                                              newrecord.additionalLocationDetails = value;
                                                                                              return newrecord;
                                                                                            })),
                                                                                      context
                                                                                    ]);
                                                                              })
                                                                          }),
                                                                      tmp$24
                                                                    ],
                                                                    className: Cx.cx([
                                                                          css.field,
                                                                          css.expanded
                                                                        ])
                                                                  }),
                                                              className: Cx.cx([
                                                                    css.fieldRow,
                                                                    css.justified
                                                                  ])
                                                            })
                                                      ]
                                                    }),
                                                JsxRuntime.jsxs(ProjectWizardPanel.Section.make, {
                                                      title: "Primary SD-WAN Network Connection",
                                                      children: [
                                                        JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$14,
                                                                              status: result$12,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    css.requiredLabel,
                                                                                    tmp$25
                                                                                  ]),
                                                                              children: "Primary Network Type"
                                                                            }),
                                                                        JsxRuntime.jsxs(Select.make, {
                                                                              id: domId$14,
                                                                              value: configuration.primaryNetworkType,
                                                                              status: Belt_Option.map(result$12, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              wrapperClassName: css.selectWrapper,
                                                                              iconSize: "XXS",
                                                                              iconColor: "GrayText",
                                                                              onChange: (function ($$event) {
                                                                                  var value = Belt_Option.getWithDefault(Belt_Array.getBy(options.primaryNetworkTypes, (function (x) {
                                                                                              return ReactForm.value($$event) === x;
                                                                                            })), "");
                                                                                  var match = fromString(value);
                                                                                  var exit = 0;
                                                                                  if (match !== "Mpls") {
                                                                                    exit = 1;
                                                                                  }
                                                                                  if (exit === 1) {
                                                                                    form.updateConfigurationPrimaryClassOfService(configurationIndex, (function (input, classOfServiceValue) {
                                                                                            return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                          var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                          newrecord.primaryClassOfService = classOfServiceValue;
                                                                                                          return newrecord;
                                                                                                        }));
                                                                                          }), "");
                                                                                  }
                                                                                  form.updateConfigurationPrimaryNetworkType(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                        newrecord.primaryNetworkType = value;
                                                                                                        return newrecord;
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                newrecord.primaryNetworkType = value;
                                                                                                return newrecord;
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                }),
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationPrimaryNetworkType(configurationIndex);
                                                                                }),
                                                                              children: [
                                                                                JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                                      children: "Select..."
                                                                                    }),
                                                                                Belt_Array.map(options.primaryNetworkTypes, (function (x) {
                                                                                        return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                                    value: x,
                                                                                                    children: x
                                                                                                  }, x);
                                                                                      }))
                                                                              ]
                                                                            }),
                                                                        tmp$26
                                                                      ],
                                                                      className: css.field
                                                                    }),
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: valueDomId,
                                                                              status: tmp$27,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    css.requiredLabel,
                                                                                    tmp$28
                                                                                  ]),
                                                                              children: "Bandwidth:"
                                                                            }),
                                                                        JsxRuntime.jsxs("div", {
                                                                              children: [
                                                                                JsxRuntime.jsxs("div", {
                                                                                      children: [
                                                                                        JsxRuntime.jsx(TextField.make, {
                                                                                              id: valueDomId,
                                                                                              value: Belt_Option.mapWithDefault(configuration.primaryBandwidthValue, "", (function (prim) {
                                                                                                      return String(prim);
                                                                                                    })),
                                                                                              status: Belt_Option.map(valueResult, (function (x) {
                                                                                                      if (x.TAG === "Ok") {
                                                                                                        return "Valid";
                                                                                                      } else {
                                                                                                        return "Error";
                                                                                                      }
                                                                                                    })),
                                                                                              className: Cx.cx([
                                                                                                    css.input,
                                                                                                    css.inputWithUnitValue
                                                                                                  ]),
                                                                                              onBlur: (function (param) {
                                                                                                  form.blurConfigurationPrimaryBandwidthValue(configurationIndex);
                                                                                                }),
                                                                                              onChange: (function ($$event) {
                                                                                                  var x = ReactForm.value($$event);
                                                                                                  var value;
                                                                                                  if (x === "") {
                                                                                                    value = {
                                                                                                      TAG: "Ok",
                                                                                                      _0: undefined
                                                                                                    };
                                                                                                  } else {
                                                                                                    var n = Belt_Int.fromString(x);
                                                                                                    value = n !== undefined && String(n) === x ? ({
                                                                                                          TAG: "Ok",
                                                                                                          _0: n
                                                                                                        }) : ({
                                                                                                          TAG: "Error",
                                                                                                          _0: undefined
                                                                                                        });
                                                                                                  }
                                                                                                  if (value.TAG !== "Ok") {
                                                                                                    return ;
                                                                                                  }
                                                                                                  var value$1 = value._0;
                                                                                                  form.updateConfigurationPrimaryBandwidthValue(configurationIndex, (function (input, value) {
                                                                                                          return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                                        var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                                        newrecord.primaryBandwidthValue = value;
                                                                                                                        return newrecord;
                                                                                                                      }));
                                                                                                        }), value$1);
                                                                                                  persistDraft([
                                                                                                        CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                                var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                                newrecord.primaryBandwidthValue = value$1;
                                                                                                                return newrecord;
                                                                                                              })),
                                                                                                        context
                                                                                                      ]);
                                                                                                })
                                                                                            }),
                                                                                        tmp$29
                                                                                      ],
                                                                                      className: css.inputWithUnitValueContainer
                                                                                    }),
                                                                                JsxRuntime.jsxs("div", {
                                                                                      children: [
                                                                                        JsxRuntime.jsxs(Select.make, {
                                                                                              id: unitDomId,
                                                                                              value: configuration.primaryBandwidthUnit,
                                                                                              status: Belt_Option.map(unitResult, (function (x) {
                                                                                                      if (x.TAG === "Ok") {
                                                                                                        return "Valid";
                                                                                                      } else {
                                                                                                        return "Error";
                                                                                                      }
                                                                                                    })),
                                                                                              className: Cx.cx([
                                                                                                    css.input,
                                                                                                    css.inputWithUnitUnit
                                                                                                  ]),
                                                                                              wrapperClassName: css.unitSelectWrapper,
                                                                                              iconSize: "XXS",
                                                                                              iconColor: "GrayText",
                                                                                              onChange: (function ($$event) {
                                                                                                  var value = Belt_Option.getWithDefault(Belt_Array.getBy(options.primaryBandwidthUnits, (function (x) {
                                                                                                              return ReactForm.value($$event) === x;
                                                                                                            })), "");
                                                                                                  form.updateConfigurationPrimaryBandwidthUnit(configurationIndex, (function (input, value) {
                                                                                                          return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                                        var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                                        newrecord.primaryBandwidthUnit = value;
                                                                                                                        return newrecord;
                                                                                                                      }));
                                                                                                        }), value);
                                                                                                  persistDraft([
                                                                                                        CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                                var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                                newrecord.primaryBandwidthUnit = value;
                                                                                                                return newrecord;
                                                                                                              })),
                                                                                                        context
                                                                                                      ]);
                                                                                                }),
                                                                                              onBlur: (function (param) {
                                                                                                  form.blurConfigurationPrimaryBandwidthUnit(configurationIndex);
                                                                                                }),
                                                                                              children: [
                                                                                                JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                                                      selectable: false,
                                                                                                      children: ""
                                                                                                    }),
                                                                                                Belt_Array.map(options.primaryBandwidthUnits, (function (x) {
                                                                                                        return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                                                    value: x,
                                                                                                                    children: x
                                                                                                                  }, x);
                                                                                                      }))
                                                                                              ]
                                                                                            }),
                                                                                        tmp$30
                                                                                      ],
                                                                                      className: css.inputWithUnitUnitContainer
                                                                                    })
                                                                              ],
                                                                              className: css.fieldWithUnit
                                                                            })
                                                                      ],
                                                                      className: css.field
                                                                    }),
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$15,
                                                                              status: result$13,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    tmp$31
                                                                                  ]),
                                                                              children: "Class of Service:"
                                                                            }),
                                                                        JsxRuntime.jsxs(Select.make, {
                                                                              id: domId$15,
                                                                              value: configuration.primaryClassOfService,
                                                                              disabled: configuration.primaryNetworkType !== "MPLS",
                                                                              status: Belt_Option.map(result$13, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              wrapperClassName: css.selectWrapper,
                                                                              iconSize: "XXS",
                                                                              iconColor: "GrayText",
                                                                              onChange: (function ($$event) {
                                                                                  var value = Belt_Option.getWithDefault(Belt_Array.getBy(options.primaryClassOfServices, (function (x) {
                                                                                              return ReactForm.value($$event) === x;
                                                                                            })), "");
                                                                                  form.updateConfigurationPrimaryClassOfService(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                        newrecord.primaryClassOfService = value;
                                                                                                        return newrecord;
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                newrecord.primaryClassOfService = value;
                                                                                                return newrecord;
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                }),
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationPrimaryClassOfService(configurationIndex);
                                                                                }),
                                                                              children: [
                                                                                JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                                      selectable: true,
                                                                                      children: "Select..."
                                                                                    }),
                                                                                Belt_Array.map(options.primaryClassOfServices, (function (x) {
                                                                                        return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                                    value: x,
                                                                                                    children: x
                                                                                                  }, x);
                                                                                      }))
                                                                              ]
                                                                            }),
                                                                        tmp$32
                                                                      ],
                                                                      className: css.field
                                                                    })
                                                              ],
                                                              className: Cx.cx([
                                                                    css.fieldRow,
                                                                    css.unfilled
                                                                  ])
                                                            }),
                                                        JsxRuntime.jsx("div", {
                                                              children: JsxRuntime.jsx(ProjectWizardPanel.ZebraGrid.make, {
                                                                    items: options.primaryServices,
                                                                    mobile: mobile,
                                                                    children: (function (option) {
                                                                        var domId$19 = domId("primary-services" + option, configurationIndex);
                                                                        return JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                                                                    id: domId$19,
                                                                                    size: "MD",
                                                                                    checked: Belt_SetString.has(configuration.primaryServices, option),
                                                                                    containerClassName: css.checkbox,
                                                                                    onChange: (function ($$event) {
                                                                                        var value = ReactForm.checked($$event) ? Belt_SetString.add(configuration.primaryServices, option) : Belt_SetString.remove(configuration.primaryServices, option);
                                                                                        form.updateConfigurationPrimaryServices(configurationIndex, (function (input, value) {
                                                                                                return CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                              var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                              newrecord.primaryServices = value;
                                                                                                              return newrecord;
                                                                                                            }));
                                                                                              }), value);
                                                                                        persistDraft([
                                                                                              CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                      var newrecord = Caml_obj.obj_dup(configuration);
                                                                                                      newrecord.primaryServices = value;
                                                                                                      return newrecord;
                                                                                                    })),
                                                                                              context
                                                                                            ]);
                                                                                      }),
                                                                                    onBlur: (function (param) {
                                                                                        form.blurConfigurationPrimaryServices(configurationIndex);
                                                                                      }),
                                                                                    children: option
                                                                                  }, domId$19);
                                                                      })
                                                                  }),
                                                              className: Cx.cx([
                                                                    css.field,
                                                                    css.expanded
                                                                  ])
                                                            })
                                                      ]
                                                    }),
                                                JsxRuntime.jsx(ProjectWizardPanel.CollapsableSection.make, {
                                                      title: "Diverse SD-WAN Network Connection (Optional)",
                                                      visibility: visibility$1,
                                                      toggleVisibility: (function () {
                                                          dispatch({
                                                                TAG: "ToggleDiverseNetworkConnectionVisibility",
                                                                at: configurationIndex
                                                              });
                                                        }),
                                                      children: tmp$33
                                                    }),
                                                JsxRuntime.jsx(ProjectWizardPanel.CollapsableSection.make, {
                                                      title: "SD-WAN Features (Optional)",
                                                      visibility: visibility$2,
                                                      toggleVisibility: (function () {
                                                          dispatch({
                                                                TAG: "ToggleSdWanFeaturesVisibility",
                                                                at: configurationIndex
                                                              });
                                                        }),
                                                      children: tmp$42
                                                    }),
                                                JsxRuntime.jsx(ProjectWizardPanel.CollapsableSection.make, {
                                                      title: "Additional Requirements (Optional)",
                                                      visibility: visibility$3,
                                                      toggleVisibility: (function () {
                                                          dispatch({
                                                                TAG: "ToggleAdditionalRequirementsVisibility",
                                                                at: configurationIndex
                                                              });
                                                        }),
                                                      children: tmp$43
                                                    })
                                              ]
                                            });
                                      } else {
                                        tmp = null;
                                      }
                                      return JsxRuntime.jsx(ProjectWizardPanel.make, {
                                                  title: configurationTitle(configurationIndex),
                                                  visibility: visibility,
                                                  toggleVisibility: (function () {
                                                      dispatch({
                                                            TAG: "TogglePanelVisibility",
                                                            at: configurationIndex
                                                          });
                                                    }),
                                                  deleteControl: match.length !== 1 ? (function () {
                                                        form.removeConfiguration(configurationIndex);
                                                        dispatch({
                                                              TAG: "RemovePanel",
                                                              at: configurationIndex
                                                            });
                                                        persistDraft([
                                                              CreateNetworkProjectSdWanNetworkConfigurationForm.Configuration.remove(form.input, configurationIndex),
                                                              context
                                                            ]);
                                                      }) : undefined,
                                                  children: tmp
                                                }, configuration.key);
                                    }))
                            }),
                        JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "Teal",
                              onClick: (function (param) {
                                  dispatch("AddPanel");
                                }),
                              children: "+ Add One More Network Service"
                            })
                      ]
                    }),
                JsxRuntime.jsxs(ProjectWizard.Content.make, {
                      children: [
                        JsxRuntime.jsx(CreateNetworkProjectSdWanNetworkConfiguration_DocumentsWithRfpDetails.make, {
                              alreadyUploadedDocuments: Belt_Array.keep(alreadyUploadedDocuments, (function ($$document) {
                                      return !Js_array.includes($$document.id, form.input.deletedDocumentIds);
                                    })),
                              documents: form.input.documents,
                              removeAlreadyUploadedDocument: (function ($$document) {
                                  dispatch({
                                        TAG: "RemoveAlreadUploadedDocument",
                                        _0: $$document
                                      });
                                }),
                              removeDocument: (function ($$document) {
                                  dispatch({
                                        TAG: "RemoveDocument",
                                        _0: $$document
                                      });
                                })
                            }),
                        tmp,
                        JsxRuntime.jsx(Uploader.make, {
                              allowed: [
                                "Pdf",
                                "Doc",
                                "Docx",
                                "Csv",
                                "Vsd",
                                "Xls",
                                "Xlsx"
                              ],
                              multiple: true,
                              className: css.uploader,
                              upload: (function (files) {
                                  Belt_Array.map(files, (function (file) {
                                          var match = Uploader.FileType.fromMime(file.type);
                                          switch (match) {
                                            case "Jpg" :
                                            case "Png" :
                                            case "Avi" :
                                            case "Mp4" :
                                            case "Mov" :
                                            case "Wmv" :
                                                return ;
                                            default:
                                              return dispatch({
                                                          TAG: "AddDocument",
                                                          _0: file
                                                        });
                                          }
                                        }));
                                }),
                              setUploadHandler: (function (param) {
                                  
                                }),
                              children: JsxRuntime.jsxs(Button.make, {
                                    size: "SM",
                                    color: "Teal",
                                    buttonClassName: css.uploadDocumentButton,
                                    children: [
                                      JsxRuntime.jsx(IconUpload.make, {
                                            size: "LG",
                                            color: "White",
                                            className: css.uploadDocumentIcon
                                          }),
                                      "Bulk Upload"
                                    ]
                                  })
                            })
                      ]
                    }),
                JsxRuntime.jsx(ProjectWizardNavigation.make, {
                      stepLabel: "Step 1 of 3",
                      controls: {
                        TAG: "PreviousAndNextAndContinueLater",
                        next: tmp$2,
                        previous: [
                          "Cancel",
                          (function () {
                              cancelCreation();
                            }),
                          "Enabled"
                        ],
                        continueLater: tmp$5
                      },
                      mobile: mobile
                    }),
                JsxRuntime.jsx(ReactNotifications.NotificationContainer, {}),
                tmp$6
              ]
            });
}

var Configuration;

var Options;

var make = CreateNetworkProjectSdWanNetworkConfiguration;

export {
  Configuration ,
  Options ,
  css ,
  NetworkConnectionType ,
  domId ,
  configurationTitle ,
  Visibilities ,
  ValidateSaveAndContinueLaterForm ,
  Api$1 as Api,
  isAlreadyDocumentsAvailable ,
  isRegionFieldVisible ,
  make ,
}
/* css Not a pure module */
